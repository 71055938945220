import React from 'react';
import { createUseStyles } from 'react-jss';
import Typography from 'src/components/core/Typography';
import {
  breakpoint, pxToRem,
  spacing,
  theme,
} from 'src/theme';
import SectionContainer from '../shared/SectionContainer';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'linear-gradient(180deg, #EBF1FF 50.06%, rgba(235, 241, 255, 0) 100%)',
    paddingTop: spacing(10),
    paddingBottom: spacing(10),
  },
  underline: {
    marginBottom: spacing(10),
    [breakpoint.down('md')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  pricingBox: {
    display: 'flex',
    marginTop: spacing(10),
    background: 'linear-gradient(109deg, #13266D 0.49%, #00B6B6 130.79%), #4282FF',
    maxWidth: pxToRem(1200),
    boxShadow: '4px 12px 24px rgba(0, 0, 0, 0.2)',
    borderRadius: 20,
    padding: spacing(10),
    [breakpoint.down('md')]: {
      flexDirection: 'column',
    },
  },
  pricingBoxLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pricingBoxRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: spacing(4),
    marginBottom: spacing(4),
  },
  verticalDivider: {
    height: 180,
    width: 1,
    backgroundColor: theme.palette.primary.WHITE,
    marginTop: spacing(4),
    marginBottom: spacing(4),
    marginLeft: spacing(8),
    marginRight: spacing(8),
    [breakpoint.down('md')]: {
      display: 'none',
    },
  },
  check: {
    display: 'inline-block',
    color: theme.palette.primary.TEAL,
    width: pxToRem(24),
    marginRight: spacing(2),
  },
});

const Pricing: React.FC = () => {
  const classes = useStyles();
  return (
    <SectionContainer className={classes.container}>
      <Typography
        variant="h2"
        color="blue"
        underline
        underlineClassName={classes.underline}
      >
        Custom Pricing Tailored to Your Needs
      </Typography>
      <Typography variant="h3" color="blue">
        No setup fees, hidden fees, or annual contracts.
      </Typography>
      <div className={classes.pricingBox}>
        <div className={classes.pricingBoxLeft}>
          <Typography variant="h3" color="white" centered>
            Affordable Plans
            <br />
            Starting at
          </Typography>
          <Typography variant="h2" color="teal" centered>
            $30
          </Typography>
          <Typography variant="p3" color="white" centered>
            Per Month
          </Typography>
        </div>
        <div className={classes.verticalDivider} />
        <div className={classes.pricingBoxRight}>
          <Typography variant="p2" color="white" style={{ maxWidth: pxToRem(450) }}>
            Our social media management services are designed to be flexible and fit your unique
            needs. Pricing starts at just $30 per month and is customized based on the level of
            <strong>content creation, visual design, and posting frequency</strong>
            {' '}
            that best suits your business.
          </Typography>
        </div>
      </div>
    </SectionContainer>
  );
};

export default Pricing;
