import React from 'react';
import ApiWrapper from 'src/components/ApiWrapper';
import Seo from 'src/components/Seo';
import Layout from 'src/layouts/Page';
import FaqSection, { FaqTypes } from 'src/sections/shared/Faq';
import BuiltSection from 'src/sections/Social/Built';
import Dashboard from 'src/sections/Social/Dashboard';
import GetStarted from 'src/sections/Social/GetStarted';
import Hero from 'src/sections/Social/Hero';
import Pricing from 'src/sections/Social/Pricing';

const ProductPage: React.FC = () => (
  <ApiWrapper apiServices={['home-api']}>
    <Layout>
      <Seo
        title="Social - Bizwise"
      />
      <Hero />
      <Dashboard />
      <BuiltSection />
      <Pricing />
      <FaqSection types={[FaqTypes.SOCIAL_MODULE]} />
      <GetStarted />
    </Layout>
  </ApiWrapper>

);

export default ProductPage;
