import cx from 'clsx';
import React from 'react';
import { createUseStyles } from 'react-jss';
import Button from 'src/components/core/Button';
import Typography from 'src/components/core/Typography';
import NavbarSpacer from 'src/components/Navbar/Spacer';
import { useSectionAboveStyles } from 'src/sections/shared/SectionWithVideoOverlap';
import SlantedSection from 'src/sections/shared/SlantedSection';
import { breakpoint, pxToRem, spacing } from 'src/theme';
import { assetLink, openIntercom } from 'src/utils';

const useStyles = createUseStyles({
  container: {
    height: pxToRem(650),
    minHeight: pxToRem(650),
    [breakpoint.down('md')]: {
      height: 'auto',
      minHeight: 'auto',
    },
  },
  content: {
    marginLeft: 'auto',
    paddingTop: spacing(12),
    paddingLeft: spacing(12),
    maxWidth: pxToRem(580),
    [breakpoint.down('md')]: {
      paddingLeft: spacing(6),
      paddingRight: spacing(6),
      paddingTop: spacing(6),
      paddingBottom: spacing(20),
      marginLeft: 0,
    },
    [breakpoint.down('sm')]: {
      paddingBottom: pxToRem(60),
    },
  },
  contentBackground: {
    background:
      'radial-gradient(153.68% 510.76% at 153.68% 79.94%, #3D78B8 0%, #13266D 92.07%), #13296B',
    [breakpoint.down('sm')]: {
      background:
        'linear-gradient(107.95deg, #13266D -12.75%, #5FBCFF 253.76%), #13296B',
    },
  },
  text: {
    marginTop: spacing(4),
    maxWidth: pxToRem(570),
    [breakpoint.down('sm')]: {
      width: '100%',
    },
  },
  header: {
    textTransform: 'capitalize',
  },
});

const Hero: React.FC = () => {
  const classes = useStyles();
  const videoPaddingClasses = useSectionAboveStyles();

  return (
    <SlantedSection
      img={(
        <img
          src={assetLink('landing-ui/product-images/SocialHero.png')}
          alt="Hero"
        />
      )}
      contentWidth={0.6}
      slantAmount={0.25}
      className={classes.container}
      contentClassName={classes.contentBackground}
      showNavbarGradient
    >
      <div className={cx(classes.content, videoPaddingClasses.root)}>
        <NavbarSpacer className="hidden md:block" />
        <Typography variant="h2" color="white" className={classes.header}>
          Transform Your Social Media into a Growth Engine
        </Typography>
        <Typography variant="p1" color="white" className={classes.text}>
          Stand out with unique content, stunning visuals, and hassle-free management of your
          social media accounts.
        </Typography>
        <Button
          onClick={openIntercom}
          variant="filled"
          color="teal"
          style={{ marginTop: spacing(8), minWidth: pxToRem(200) }}
        >
          Get Started
        </Button>
      </div>
    </SlantedSection>
  );
};

export default Hero;
