import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { createUseStyles } from 'react-jss';
import LaptopScreenshot from 'src/components/LaptopScreenshot';
import { breakpoint } from 'src/theme';
import { assetLink } from 'src/utils';
import RightLeftChecklistSection from '../shared/RightLeftChecklist';

const useStyles = createUseStyles({
  video: {
    width: '85%',
    [breakpoint.down('md')]: {
      width: '100%',
    },
  },
  demo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  smallDemo: {
    marginTop: -70,
    marginBottom: 20,
    [breakpoint.up('md')]: {
      marginLeft: '25%',
    },
  },
});

const content1 = [
  <p>
    <strong>Attract New Followers:</strong>
    {' '}
    Grow your audience and increase brand awareness by reaching new potential customers.
  </p>,
  <p>
    <strong>Local Visibility:</strong>
    {' '}
    Make your business easily searchable in local social media groups, enhancing your community
    presence.
  </p>,
  <p>
    <strong>Customer Loyalty:</strong>
    {' '}
    Build and maintain strong relationships with your customers, encouraging repeat business.
  </p>,
  <p>
    <strong>Drive Business Growth:</strong>
    {' '}
    Leverage the power of social media to expand your reach.
  </p>,
];
const content2 = [
  <p>
    <strong>Active Engagement:</strong>
    {' '}
    Keep your social media pages vibrant and active with consistent, engaging posts.
  </p>,

  <p>
    <strong>Advertise with Impact:</strong>
    {' '}
    Promote your products or services effectively, driving more sales through targeted social media
    campaigns.
  </p>,

  <p>
    <strong>Customer Updates:</strong>
    {' '}
    Regular posts keep your existing customers informed about new offers, events, or changes.
  </p>,

  <p>
    <strong>Professional Design:</strong>
    {' '}
    Impress your audience with high-quality, visually appealing posts.
  </p>,
];

const BuiltSection: React.FC = () => {
  const classes = useStyles();
  // TODO: Instead of showing the WebEditorVideo,
  // show a snapshot of the website for their business type
  // TODO: Change "Your Customers" based on business type (e.g. "Your Clients")
  return (
    <RightLeftChecklistSection
      title="Drive Engagement and Growth with Strategic Social Media Management"
      rightContent={{
        title: 'Grow Your Business with Social Media',
        checklist: content1,
        media: (
          <LaptopScreenshot src={assetLink('landing-ui/product-images/SocialDashboard1.jpg')} className={classes.video} />
        ),
      }}
      leftContent={{
        title: 'Comprehensive Social Media Strategy',
        checklist: content2,
        media: (
          <StaticImage
            width={600}
            src="https://assets.bizwise.com/landing-ui/product-images/SocialDashboard2.jpg"
            alt="Programmer woman at computer"
            style={{
              borderRadius: 20,
              filter: 'drop-shadow(0px 25px 50px rgba(0, 0, 0, 0.25))',
            }}
          />
        ),
      }}
    />
  );
};

export default BuiltSection;
